<template>
    <v-container fluid class="tb-layout-browse">
       <!---BOC:[breadcrumb]-->
       <ABreadcrumb :items="breadcrumbTabulator.items" />
      <!--EOC-->
      <div v-if="this.rolePermission[`${model.permissionKeys.browse}`].isAllowed">
      <BreadHeader :title="modelName">

        <v-btn
          :disabled="!model.add"
          color="primary"
          :to="{
            name: 'PageServiceModelBreadTabulatorAdd',
            params: {
              serviceKey: childModel.serviceKey,
              modelKey: childModel.key,
              modelId: modelId,
            },
          }"
          >+ New {{ childModel.name.singular }}</v-btn
        >


        <v-btn
        :disabled="!model.edit"
        text
        color="primary"
        :to="{
          name: 'PageServiceModelBreadTabulatorRead',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >View</v-btn>

        <v-btn
        :disabled="!model.edit"
        text
        color="primary"
        :to="{
          name: 'PageServiceModelBreadTabulatorEdit',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >Edit</v-btn
      >

      <v-btn
        :disabled="!model.delete"
        text
        class="mx-1"
        color="error"
        :to="{
          name: 'PageServiceModelBreadTabulatorDelete',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >Delete</v-btn
      >
      

      
    </BreadHeader>
      <v-row class="tb-filter-section">
        <v-row no-gutters>
          <v-col md="4">
            <div class="tb-left-second-section">
              <div>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = false"
                  style="cursor: pointer;"
                >
                  Switch to Normal Filter
                </h5>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = true"
                  style="cursor: pointer;"
                >
                  Switch to Advance Filter
                </h5>
              </div>
              <div>
                <h4 v-on:click="isShow = !isShow" style="float: left">
                  Filter
                  <v-icon left> mdi mdi-menu-down </v-icon>
                </h4>
                <span v-if="switchToAdvanceFilter == false"> {{ conditions.length }} Filter Applied </span>
              </div>
            </div>
          </v-col>
          <v-col md="8">
            <!-- include column selection compnent -->
  
            <BreadTabulatorSelectColumn
              ref="selectColumn"
              :model="childModel"
              @applyColumnSelection="applyColumnSelection"
            ></BreadTabulatorSelectColumn>
  
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col md="12">
            <div class="tb-normal-filter" v-show="isShow">
              <div class="tb-filter-area" v-show="switchToAdvanceFilter == false">
                <BreadTabulatorFilterable
                  ref="filter"
                  :model="childModel"
                  :setConditions="setConditions"
                ></BreadTabulatorFilterable>
              </div>
  
              <div class="advanced-filter" v-show="switchToAdvanceFilter == true">
                <BreadTabulatorAdvanceFilterable
                  ref="advanceFilter"
                  :model="childModel"
                  :setAdvancedFilterConditions="setAdvancedFilterConditions"
                ></BreadTabulatorAdvanceFilterable>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-row>
  
      <!-- defult search area-->
      <div class="tb-search-area">
        <v-row>
          <v-col md="6">
            <BreadTabulatorSearchable
              ref="search"
              :model="childModel"
              :setConditions="setConditions"
            ></BreadTabulatorSearchable>
            <div>
              <span id="record-count" style="float: left; font-size: 12px"></span>
            </div>
          </v-col>
  
          <v-col md="6" align="right">
            <v-row no-gutters>
              <v-col md="4">
                <div>
                  <h4
                    v-if="this.dataStatus === 'active'"
                    style="margin: 10px 0px; font-size: 16px; cursor: pointer"
                    @click="callApiUrl('trash')"
                  >
                    View Deleted
                  </h4>
                  <h4
                    v-if="this.dataStatus === 'trash'"
                    style="margin: 10px 0px; font-size: 16px; cursor: pointer"
                    @click="callApiUrl('active')"
                  >
                    View All
                  </h4>
                </div>
                
              </v-col>
              <v-col md="8">
                <v-btn-toggle dense multiple class="mr-1">
                  
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on">
                        Export As<v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="downloadCsvData('all')"
                        ><v-list-item-title>CSV</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="downloadXlsxData('all')"
                        ><v-list-item-title>Excel</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="downloadPdfData('all')"
                        ><v-list-item-title>PDF</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on">
                        Selected<v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="downloadCsvData('selected')"
                        ><v-list-item-title
                          >Export CSV</v-list-item-title
                        ></v-list-item
                      >
  
                      <v-list-item @click="downloadXlsxData('selected')"
                        ><v-list-item-title
                          >Export Excel</v-list-item-title
                        ></v-list-item
                      >
  
                      <v-list-item @click="downloadPdfData('selected')"
                        ><v-list-item-title
                          >Export PDF</v-list-item-title
                        ></v-list-item
                      >
                      
                      <!-- <v-list-item @click="deleteSelectedRows"
                        ><v-list-item-title
                          >Delete</v-list-item-title
                        ></v-list-item
                      > -->
                    </v-list>
                  </v-menu>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
  
      <!-- table area with tabs-->
  
      <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="paginationSize"
        :paginationSizeSelector="paginationSizeSelector"
        :apiUrl="apiGetBrowseUrl()"
        :columns="columns"
        :modelKey="childModelKey"
      ></BreadTabulator>

    </div>
    <div v-else>
      <v-row>
        <v-col md="12">
          <v-alert
            type="error"
            dismissible
            border="left"
            elevation="2"
            icon="mdi-alert"
          >
            You don't have permission to browse this model.
          </v-alert>
        </v-col>
      </v-row>
    </div>  

    </v-container>
  </template>
  
  <script>
  //BOC:[api]
  import Api from "@/objects/api";
  //EOC
  //BOC:[breadcrumb]
  import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
  //EOC
  //BOC:[model]
  import Model from "@/objects/model";
  //EOC
  //BOC:[service]
  import Service from "@/objects/service";
  //EOC
  
  
  import { mapState } from "vuex";
  export default {
    components: {
        //
    },
    computed: mapState({
      auth: (state) => state.auth.data,
      services: (state) => state.service.data.services,
      rolePermission: (state) => state.rolePermission.data,
    }),
    props: [
      //
    ],
    data: () => ({
  
      //BOC:[api]
      api: new Api(),
      //EOC
      //BOC:[apiDeleteMultiRecords]
      apiDeleteMultiRecords: new Api(),
      //EOC
      //BOC:[breadcrumb]
      breadcrumbTabulator: new BreadcrumbTabulator(),
      //EOC
  
      //BOC:[model]
      model: new Model(),
      childModel : new Model(),
      //EOC
      //BOC:[listAllViews]
      listAllViews: [],
      //EOC
  
      dataStatus: "active",
  
      //BOC:[tools]
      tools: {
        advancedBar: [2, 3],
        enableColumnSelection: true,
        enableRowSelection: true,
        enableSearch: true,
        showAdvancedBar: true,
      },
      //EOC
      //BOC:[filters]
      filters: [],
      //EOC
      //BOC:[service]
      service: new Service(),
      //EOC
      selectedRows: [],
      records: [],
      //BOC:[conditions]
      conditions: [],
      //EOC
      selectedCustomColums: ['name'],
      selectedAdvanceFilterConditions: [],
      selectedFilterConditions: [],
      paginationSizeSelector: [10, 50, 100],
      paginationSize: 10,
      columns: [],
      userRole: "admin",
      modelKey: "",
      modelId: "",
      parentItemName: '',
      childModelKey: "",
      switchToAdvanceFilter: false,
      isShow: false,
      modelName: null,
    }),
    watch: {
      //
    },
    created() {
      //BOC:[model]
      this.model.getByKey(this.$route.params.modelKey);
      //this.model.getByKey("demoProduct");
      //EOC

      this.childModel.getByKey(this.$route.params.childModelKey);
  
      this.modelKey = this.$route.params.modelKey;
      this.modelId = this.$route.params.modelId;
      this.childModelKey = this.$route.params.childModelKey;
      //this.modelName = `${this.model.name.singular} ${this.modelId}`;

      //console.log(this.modelId, this.parentColumnName);
  
      //EOC
      this.columns = this.$_.filter(
        this.childModel.browse.table.headers,
        (headers) => {
          return headers;
        }
      );
  
      //BOC: convert format of columns with dataType = modelParent
      var columnIndex = 0;
      for (var column of this.columns) {
        if (column.dataType == "modelParent") {
          /* var parentColumnName = column.field; */
          var parentModel = new Model();
          parentModel.getByKey(column.modelKey);
          this.columns[columnIndex].formatter = (cell) => {
            var parent = cell.getRow().getData()[this.$_ucfirst(parentModel.key)];
            var parentLabel = parent.id;
            if (parent.name) parentLabel = parent.name;
            if (parentModel.column && parentModel.column.text)
              parentLabel = parent[parentModel.column.text];
  
            return `<span>${parentLabel}</span>`;
          };

          this.columns[columnIndex].accessorDownload = (e, data) => {
            return data[this.$_ucfirst(parentModel.key)].name;
          }
  
        }
        columnIndex++;
      }
      //EOC

      /* this.columns.push({
        title:"Action",
        filterable: false,
        headerSort: false,
        formatter: () => {
          return `<span class="">Read</span>`;
        },

        cellClick:(e, cell) => {
            e.preventDefault();
            this.$router.push({
              name: "PageServiceModelBreadTabulatorRead",
              params: {
                serviceKey: this.model.serviceKey,
                modelKey: this.childModelKey,
                modelId: cell.getRow().getData().id,
              },
            });
        },
        download: false,

      }); */
  
      //BOC:[service]
      if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
      //EOC
      //BOC:[tools]
      if (this.model.browse.tools) this.tools = this.model.browse.tools;
      //EOC
  
      //BOC:[breadcrumb]
      /* this.breadcrumbTabulator.addForOperationParentBrowse({
        model: this.model,
        services: this.services,
        parentName: this.parentItemName,
        childModelKey: this.childModelKey,
        modelId: this.modelId,
      }); */
      //EOC

      this.api.setMethod("GET");
        this.api.setUrl(
          `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
            this.model.key
          }/${this.modelId}/read`
        );

        this.api.setCallbackCompleted((response) => {
          this.records = response;
          this.modelName = this.records.name;

          //BOC:[breadcrumb]
          this.breadcrumbTabulator.addForOperationParentBrowse({
            model: this.model,
            services: this.services,
            parentName: this.modelName,
            childModelKey: this.childModelKey,
            modelId: this.modelId,
          });
          //EOC

        });

        this.api.fetch();

    },
    mounted() {
     //
    },
    methods: {
      callApiUrl(urlStatus) {
        if (urlStatus === "trash") {
          this.dataStatus = "trash";
          this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
        }
        if (urlStatus === "active") {
          this.dataStatus = "active";
          this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseUrl());
        }
      },
  
      //BOC:[filters]
      apiGetBrowseUrl() {

        return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/id/${this.modelId}/child/${this.childModelKey}/browse/all`;

      },
  
      apiGetBrowseTrashUrl() {

        return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/id/${this.modelId}/child/${this.childModelKey}/browse/trashed`;
      },
  
      setFiltersViaConditions(conditions) {
        this.$refs.filter.setFiltersViaConditions(conditions);
      },
      //EOC

      //BOC:[conditions]
      getConditions() {
        return this.conditions;
      },
      setConditions(conditions) {
        this.selectedFilterConditions = conditions;
        this.conditions = conditions;
        this.$refs.tabulatorDataTable.getFilterCondition(conditions);
      },
  
      setAdvancedFilterConditions(advanceFilterParams) {
        this.selectedAdvanceFilterConditions = advanceFilterParams;
        this.$refs.tabulatorDataTable.setAdvancedFilterConditions(
          advanceFilterParams
        );
      },
  
      applyColumnSelection(selectedColumns) {
        this.selectedCustomColums = selectedColumns;
        /* console.log("selectedColumns2", selectedColumns); */
        this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
      },
  
  
      downloadCsvData(rowsType) {
  
        this.$refs.tabulatorDataTable.tabulator.download(
            "csv",
            `${this.model.key}.csv`,
            {
              sheetName: `${this.model.key}`,
            },
            rowsType == 'selected' ? 'selected' : 'all'
          );
        
      },
  
      downloadXlsxData(rowsType) {
  
        this.$refs.tabulatorDataTable.tabulator.download(
          "xlsx",
          `${this.model.key}.xlsx`,
          {
            sheetName: `${this.model.key}`,
            
            //documentProcessing:function(workbook){ /* do something */ },
          },
          rowsType == 'selected' ? 'selected' : 'all'
        ); //download a xlsx file using SheetJS properties
      },
  
      downloadPdfData(rowsType) {
        this.$refs.tabulatorDataTable.tabulator.download(
          "pdf",
          `${this.model.key}.pdf`,
          {
            orientation: "portrait", //set page orientation to portrait
            title: "Demo Product Details", //add title to report
            autoTable: {
              //advanced table styling
              /* styles: {
              fillColor: [100, 255, 255], 
            },
            columnStyles: {
              id: { fillColor: 255 }, 
            },
            margin: { top: 60 }, */
            },
          },
          rowsType == 'selected' ? 'selected' : 'all'
        );
      },
  
      updateEditedCellData(cell) {
        let editedRecordId = cell.getRow().getData().id;
        let editedRecordField = cell.getField();
        let editedRecordValue = cell.getValue();
  
        if (editedRecordField !== undefined) {
          var responseData = {
            [editedRecordField]: editedRecordValue,
          };
  
          this.api.setMethod("POST");
          this.api.setParams({
            data: JSON.stringify(responseData),
          });
          this.api.setUrl(
            `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
              this.model.key
            }/${editedRecordId}/editDirectlyInTabulator`
          );
  
          this.api.setCallbackCompleted(() => {
            this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });

            
          });
  
          this.api.fetch();
        }
      },
    },
  };
  </script>
  
  
<style scoped></style>